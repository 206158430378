.headeremails {
    /* background-color: aqua; */
    display: flex;
    /* justify-content: space-between; */
    padding: 0 20px;
    flex-direction: column;
}

.alltosendemails {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: aqua; */
}

.tosend {
    margin-top: 20px;
    /* background-color: aqua; */
    padding: 20px;
    width: 30%;
    border: 2px solid #6E4E9F;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 20px;
}

.addemailform {
    /* background-color: #6E4E9F; */
    position: relative;
    width: 58%;
}

.addemailformbtn {
    height: 37px;
    position: absolute;
    right: 0;
    border-radius: 10px;
    border-top: 1px solid #6E4E9F;
    border-right: 1px solid #6E4E9F;
    border-bottom: 1px solid #6E4E9F;

}

.alltosendemails p {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    position: relative;
    /* flex-direction: column; */
    /* background-color: aqua; */
}

.alltosendemails p::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgba(128, 128, 128, 0.144);
    position: absolute;
    bottom: 0;
}

.emailbody {
    /* background-color: #6E4E9F; */
    border: 2px solid #6E4E9F;
    margin-top: 30px;
    text-align: left;
    border-radius: 20px;

}

.formalinput {
    border: 1ps solid #6f4e9f60;
    padding: 10px 10px;
    width: 30%;
    /* border-radius: 10px; */
}

.ql-toolbar {
    display: block !important;
    visibility: visible !important;
}


.selectedevent{
    background-color: #cfafec;
}