.formforpopup {
    background-color: transparent;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    /* padding: 1vh 0; */
    overflow: auto;
}

.formforpopup form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 90%;
    /* background-color: gray; */
}
.formforpopup form div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 1rem; */
    
}

.formforpopup form div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    gap: 10px;
    /* gap: 1rem; */
    
}

.formforpopup form label {
    width: 100%;
    color: #6E4E9F;
    
}



.formforpopup form input {
    width: 100%;
    height: 1.7rem;
    border-radius: 20px;
    border: 1px solid #6E4E9F;
    /* padding: 0.5rem; */
}

.formforpopup form select {
    width: 100.5%;
    height: 2rem;
    border-radius: 20px;
    border: 1px solid #6E4E9F;
    /* padding: 0.5rem; */
}
.formforpopup form button {
    width: 100%;
    height: 2rem;
    border-radius: 20px;
    border: 1px solid #fff;
    padding: 0.5rem;
    background-color: #6E4E9F;
    color: white;
    cursor: pointer;
}
.formforpopup form button:hover {
    background-color: #6136a2;
}
