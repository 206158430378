.deshboardmain {
    background-color: rgb(188, 194, 254);
    /* background: rgb(188, 194, 254);
    background: linear-gradient(90deg, rgba(188, 194, 254, 1) 26%, rgba(225, 213, 255, 1) 50%, rgba(210, 205, 255, 1) 73%); */
    display: flex;
    padding: 120px 20px 20px 20px;
    justify-content: space-between;
    min-height: 85vh;
    /* background-color: #D1CCFE; */
}

.testttttt {
    /* background-color: #6E4E9F; */
    overflow: hidden;
    position: relative;
}

.testttttt {
    position: relative;
}

.testttttt::before {
    content: "T4-";
    color: black;
    font-size: small;
    position: absolute;
    left: 5px;
    bottom: 6px;
}

.testttttt::after {
    content: "-YGM";
    color: black;
    font-size: small;
    position: absolute;
    left: 138px;
    bottom: 6px;
}


.mainbody {
    width: 72%;
    /* background-color: rgb(250, 161, 60); */
    height: fit-content;
    min-height: 610px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    padding: 10px 2%;
}

.piechars {
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin-top: 150px;
}

.kitscouts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

.boxxxx {
    border: 1px solid #6f4fa0;
    width: 15%;
    height: 70px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    font-weight: 900;
    /* color: #fff; */
    /* justify-content: center; */
    align-items: center;
    color: #6E4E9F;
    cursor: pointer;
    transition: 0.5s ease;

}

.boxxxx:hover {
    box-shadow: 0 0 10px 1px #6E4E9F;
}

.bbbbg {
    background-color: #6E4E9F;
    width: 100%;
    color: #fff;
    border-radius: 10px;
    padding: 8px 0;
    height: 20px;
}

.imp {
    margin-bottom: 70px;
    height: fit-content;
    max-height: 50vh;
    overflow-y: auto;
    border-bottom: 1px solid #6f4e9f69;
    border-radius: 15px;
}

.div1 {
    width: 100%;
}






.kitsbarssandcircle {
    /* background-color: aqua; */
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    /* padding: 0 20px; */
}

.kitsbarrr {
    /* background-color: #6f4fa0; */
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;

}

.barmain {
    background-color: #f1ebff;
    display: flex;
    width: 95%;
    border-radius: 20px;
    display: flex;
    align-items: center;



}

.barfill {
    /* background-color: tomato; */
    width: 67%;
    border-radius: 20px;
}

.barfill2 {
    /* background: rgb(110, 78, 159);
    background: linear-gradient(90deg, rgba(110, 78, 159, 1) 22%, rgba(177, 161, 201, 1) 50%, rgba(110, 78, 159, 1) 87%); */
    width: 4%;
    padding: 5px 4%;
    border-radius: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    text-align: right;
}

.barname {
    width: 30%;
    text-align: right;
    padding-right: 2%;
    color: #6E4E9F;
}

.kitsresutldcircle {
    /* background-color: #6f4fa0; */
    width: 30%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
}


@media screen and (max-width:1060px) {


    .kitscouts {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        gap: 10px;
    }

    .boxxxx {
        border: 1px solid #6f4fa0;
        width: 50%;
        height: 100px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        font-weight: 900;
        /* color: #fff; */
        /* justify-content: center; */
        align-items: center;
        color: #6E4E9F;
        cursor: pointer;
        transition: 0.5s ease;

    }


    .bbbbg {
        background-color: #6E4E9F;
        width: 100%;
        color: #fff;
        border-radius: 10px;
        padding: 8px 0;
        height: 40px;
    }

    .kitsresutldcircle {
        /* background-color: #6f4fa0; */
        width: 100%;
        margin-top: 50px;
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
    }

    .kitsbarssandcircle {
        /* background-color: aqua; */
        display: flex;
        margin-top: 40px;
        justify-content: space-between;
        flex-direction: column;
        /* padding: 0 20px; */
    }

    .kitsbarrr {
        /* background-color: #6f4fa0; */
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
    }

    .kitsbarrr h3 {
        font-size: small;
    }

    .deshboardmain {
        flex-direction: column;
        padding: 120px 5px;
    }

    .mainbody {
        width: 97%;
        /* background-color: yellow; */
        overflow-x: scroll;
        font-size: smaller;
    }

    .piechars {
        flex-direction: column;

    }

    .div1 {
        width: 100%;
    }

}