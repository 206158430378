.eventform div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left !important;
  text-align: left;
}

.eventform {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left !important;
  text-align: left;
  gap: 20px;
}

.eventform div input {
  width: 96%;
}

.eventform div .input {
  width: 99.8%;
  height: 30px !important;
}

.eventform div select {
  width: 99.8%;
}

.css-dev-only-do-not-override-xu9wm8 {
  height: auto !important;
}

.ql-snow {
  display: block !important;
}

.divofevent {
  background-color: white;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between !important;
  padding: 2%;
  width: 96% !important;
}

.contantdiv {
  background-color: white;
  border-radius: 5px;
  width: 96% !important;
  padding: 2%;
}

/* .eventform p {
    position: relative;
}

.eventform p::after {
    content: "";
    width: 95%;
    position: absolute;
    height: 1px;
    background-color: gray;
    left: 0;
    bottom: -10px;
} */

.fleidbox {
  background-color: white;
  display: flex !important;
  flex-direction: column !important;
  padding: 30px 2%;
  width: 96% !important;
  justify-content: space-between !important;
  gap: 20px;
  flex-wrap: wrap !important;
}

.fleidbox div {
  display: flex;
  flex-direction: row !important;
  justify-content: baseline !important;
  gap: 20px;
  /* background-color: black; */
  /* width: fit-content; */
}

.fleidbox div div {
  display: flex !important;
  background-color: #cac9c9;
  text-align: center !important;
  justify-content: center !important;
  padding: 10px 20px;
  border-radius: 5px;
}

.hovarrrr:hover {
  cursor: pointer;
  background-color: #d1cfcf;
  scale: 1.02;
}

.headerforimg {
  /* background-color: aqua; */
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.coverphoto {
  width: 20%;
  border-radius: 10px;
  /* background-color: #6e4e9f; */
  margin-bottom: 5px;
}

.questionnew {
  background-color: #ddd2e7;
  border-radius: 20px;
  padding: 25px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: fit-content;
  position: relative;
}

.questiontitle {
  /* height: 40px; */
  display: flex;
  align-items: center;
  padding: 8px;
  padding-left: 13px;
  padding-right: 13px;
  color: white;
  max-width: 97%;
  background: #6e4e9f;
  border-radius: 15px;
  text-align: left;
}

.date {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #6e4e9f;
  font-size: 15px;
  font-weight: 600;
  padding-left: 5px;
}
.date2 {
  margin-top: 7px;
  margin-bottom: 0px;
  color: #6e4e9f;
  font-size: 15px;
  font-weight: 600;
  padding-left: 5px;
}
.about {
  display: flex;
  align-items: center;
  /* padding: 8px; */
  padding-left: 16px;
  padding-right: 16px;
  /* color: white; */
  color: #6e4e9f;
  width: 97%;
  /* background:#6e4e9f; */
  background: white;
  border-radius: 15px;
}

.about2 {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: flex-start;
  padding: 16px;
  padding-left: 16px;
  padding-right: 16px;
  /* color: white; */
  color: #6e4e9f;
  width: 97%;
  /* background:#6e4e9f; */
  background: white;
  border-radius: 15px;
}


.questionboxnew {
  /* background-color: aqua; */
  /* border: 4px solid #6E4E9F; */
  border-radius: 20px;
  justify-content: baseline;
  /* margin-top: 50px; */
  display: flex;
  /* padding: 20px; */
  flex-direction: column;
  align-items: baseline;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 60px;
}

.mybutton {
  background: #6e4e9f;
  color: #ddd2e7;
  color: white;
  border: none;
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 7px;
  display: flex;
  margin-bottom: 5px;
}
.mybutton2 {
  color: #6e4e9f;
  background: #ddd2e7;
  /* color: white; */
  border: none;
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 7px;
  display: flex;
  margin-bottom: 5px;
  border: 2px solid red;
}

.participants {
  color: #6e4e9f;
  /* padding-top: 20px; */
}
.dltbtndivnew {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
}
.mybtndiv{
  display: flex;
  justify-content:flex-end;
  width: 100%;
}


.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.meeting-container {
  display: flex;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  width: 700px;
  max-width: 90%;
}

.video-preview {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-placeholder {
  width: 300px;
  height: auto;
  border-radius: 12px;
}

.meeting-details {
  flex: 1;
  padding-left: 20px;
}

.join-room {
  text-align: center;
}

.join-room h2 {
  margin-bottom: 10px;
}

.join-room input {
  padding: 10px;
  width: 80%;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 16px;
}

.join-room button {
  padding: 10px 20px;
  background-color: #0587ef;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.join-room button:hover {
  background-color: #4180b7;
}

.share-link {
  margin-top: 20px;
  text-align: center;
}

.css-dev-only-do-not-override-qnu6hi {
  height: auto !important;
}

.imgwaladiv {
  width: 40%;
  position: relative;
}
.imgwaladiv2 {
  position: absolute;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.205);
  opacity: 0;
  left: 0;
  height: -webkit-fill-available;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
}
.imgwaladiv2:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.205);
}

.share-link .link-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
}

.share-link .link {
  color: #0587ef;
  cursor: pointer;
}

.share-link button {
  padding: 5px 10px;
  background-color: #0587ef;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.share-link button:hover {
  background-color: #4180b7;
}

.meradiv {
  position: absolute;
  background-color: transparent !important;
  width: 50% !important;
  z-index: 2;
  /* top: 0;
    left: 0; */
}

.position {
  position: relative;
}
.position2 {
  position: absolute;
  right: 22px;
}

.qqqqqqq {
  background-color: #ddd2e7;
  /* border: 1px solid #6e4e9f; */
  padding: 10px 20px;
  color: #6e4e9f;
  cursor: pointer;
}


.qqqqqqq:nth-child(1){
 border-radius: 10px 0 0 10px;
}

.qqqqqqq:nth-child(2){
  border-radius: 0 10px 10px 0;
 }

.qqqqqqqqqqq {
  background-color: #6e4e9f;
  position: relative;
  color: #fff;
  z-index: 10;
}
.qqqqqqqqqqq::after {
  background-color: #6e4e9f;
  content: "";
  width: 30px;
  z-index: 1;
  height: 30px;
  bottom: -10px;
  border-radius: 150% 0px 0 0px;
  position: absolute;
  color: #fff;
  left: 50%;
  transform: rotate(45deg);
}

@media screen and (max-width: 1060px) {
  .meradiv {
    position: absolute;
    background-color: transparent !important;
    width: 97.5% !important;
    z-index: 2;
    /* top: 0;
      left: 0; */
  }
}
